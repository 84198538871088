import React from 'react'
import { IonApp, IonBackButton, IonButtons, IonHeader, IonIcon, IonSearchbar } from '@ionic/react';
import {chevronBackSharp,addCircle,searchSharp,peopleCircleOutline} from 'ionicons/icons';
import {Link} from 'react-router-dom';
import './YourClasses.css';
const YourClasses : React.FC=()=>{

    return(
       <IonApp style={{maxWidth:"450px",maxHeight:"750px"}}>
        <div>
      
       <div className="heder">
       <IonButtons slot="start" >
          <IonBackButton defaultHref='/'/>
          </IonButtons>
              <div id="tle">
                  Your classes
              </div>
              <div>
                  <IonIcon color="primary"
                  size="large" icon={addCircle}/>
              </div>
              </div>
              <div id="div-search">
       <div>
           <IonSearchbar
            placeholder="Search for courses" className="search-bar"
              mode='ios' /> 
             </div>
            <div className="ion-button">
            <IonIcon  style={{color:"white"}} size="medium" icon={searchSharp}></IonIcon>
            </div>
        </div>
       
       <div className='grd'>
            <div >
             
             <div className="div-button">
                WD
             </div>
             <div id="grid1-2">
                 Web Developement
             </div>
             <div id="grid1-3">
             <div>
                <IonIcon style={{color: "rgb(129, 127, 127)"}}
                size="small"slot="start" icon={peopleCircleOutline}/></div>
                 <div className="tcher">Teacher Name</div>
             </div>
            </div>


            <div >
             
             <div className="div-button2">
                    SS
             </div>
             <div id="grid1-2">
                 System Software
             </div>
             <div id="grid1-3">
                <div>
                <IonIcon style={{color: "rgb(129, 127, 127)"}}
                size="small"slot="start" icon={peopleCircleOutline}/></div>
                 <div className="tcher">Teacher Name</div>
             </div>
            </div>


                  <div >
             
             <div className="div-button3">
                WD
             </div>
             <div id="grid1-2">
                 Web Developement
             </div>
             <div id="grid1-3">
             <div>
                <IonIcon style={{color: "rgb(129, 127, 127)"}}
                size="small"slot="start" icon={peopleCircleOutline}/></div>
                 <div className="tcher">Teacher Name</div>
             </div>
            </div>

                  <div >
             
             <div className="div-button4">
                AC
             </div>
             <div id="grid1-2">
                Advanced Computers
             </div>
             <div id="grid1-3">
             <div>
                <IonIcon style={{color: "rgb(129, 127, 127)"}}
                size="small"slot="start" icon={peopleCircleOutline}/></div>
                 <div className="tcher">Teacher Name</div>
             </div>
            </div>


                  <div >
             
             <div className="div-button5">
                SA
             </div>
             <div id="grid1-2">
                Storage Area Network 
             </div>
             <div id="grid1-3">
             <div>
                <IonIcon style={{color: "rgb(129, 127, 127)"}}
                size="small"slot="start" icon={peopleCircleOutline}/></div>
                 <div className="tcher">Teacher Name</div>
             </div>
            </div>
           

            
             
            
         </div> 
        
        </div>
        </IonApp>
       
    );
}
export default YourClasses;