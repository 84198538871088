import React ,{useState}from 'react';
import './index.css';
import {Redirect,Route,Link} from 'react-router-dom';
import {IonReactRouter} from '@ionic/react-router';
import PendingAssignments from './Components/PendingAssignments';
import Liveclass from './Components/LiveClasses';


import { IonApp,
   IonRouterOutlet,
    IonHeader,
     IonToolbar,
     IonSearchbar
     ,IonContent,
      IonIcon,IonTabBar,
       IonButton, IonTabButton, 
       IonLabel, 
        IonItem, IonSlides,
          IonSlide, IonCard, 
          IonThumbnail, IonImg, IonText, IonPage, IonNav, IonSplitPane, IonTabs, IonTab, IonFooter, IonActionSheet} from '@ionic/react';
import { documentTextOutline,
  notifications,apps,
  homeOutline,fileTrayFullOutline,
  readerOutline,peopleOutline,searchSharp, videocamSharp, time, personCircleSharp, videocam, mic, phoneLandscape, micOff, videocamOff } from 'ionicons/icons';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
 
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
 
/* Theme variables */
import './theme/variables.css';
const slideOpts = {
  slidesPerView:1.5
};
const slideSelection={
  slidesPerView:1.93

}
const slideSelection2={
  slidesPerView:2.05

}


const Home: React.FC= () => {
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [micbarOff,setMicOn]=useState(micOff);
  const [mictextOff,setMicText]=useState('Mic is off');
  const [vdoTextOff,setVdoText]=useState('Video is off')
  const [videoOff,setVideoOn]=useState(videocamOff);
  const [destructive,setDestructive]=useState("destructive");
  const [destructive1,setDestructive1]=useState("destructive");

  return(
  <IonApp style={{maxWidth:"450px",maxHeight:"750px"}}>
   <IonContent >
  
     <div id="div">
         <IonIcon size="medium"  slot="start" icon={apps}/>
          <div >Home</div> 
          <IonIcon  size="medium" slot="end"   icon={notifications}/>   
     </div> 
     <IonItem id="ion-item" lines="none">
     <IonThumbnail slot="end">
            <IonImg id="img" src="https://habib.al-mawali.com/wp-content/uploads/IMG_4838-1-768x768.jpg" />
          </IonThumbnail>
           <IonLabel className="ion-label">
             <p>Good Morning, <span className="username">Sandip</span></p>
             <h1>Are you ready to start your class</h1>
           </IonLabel>
         </IonItem>
          
     <div className="div-search">
       <div>
           <IonSearchbar  
            placeholder="Search for courses" id="search-bar"
             /> 
             </div>
            <div className="ion-button">
            <IonIcon  style={{color:"white"}} size="medium" icon={searchSharp}></IonIcon>
            </div>
             </div>
             <IonSlides id="sld"  options={slideOpts}>
         <IonSlide >
          
          <IonItem className='slide1' lines="none">
            
           <IonThumbnail className="thumnail-ion" style={{margin:"0",padding:"0"}} slot="end">
           <img src="https://img.icons8.com/bubbles/500/000000/user-male.png"/>
           </IonThumbnail>
          
          
          <IonText mode="ios" className="ion-text" color="dark">
             <h5>Attention !!</h5>
             <p> You have Some of yours assignments pending</p>
             <Link to="/pending" className='link' > View Assignment </Link>
          </IonText>
          </IonItem>
          </IonSlide>     
        
          <IonSlide >
          <IonItem className='slide1' lines="none">
            <IonThumbnail style={{margin:"0"}} slot="end">
            <div id="attendence"> 90% <span>Attendence</span></div>
          </IonThumbnail>
          
          <IonText mode="ios" className="ion-text" color="dark">
             <h5>Great job!!</h5>
             <p> You have 90% attendence of your classes</p>
             <Link to="/" className='link' > View Attendence </Link>
          </IonText>
          
          </IonItem>
           </IonSlide>
           </IonSlides >
           <div className="instruct">
              <div>Live Classes</div>
              
                <Link to="/liveclass"
                 className="lnk"  >View all</Link>
              
          </div>
    
          <div id='container'>
              <ul id='progressbar'>
                <li className='active'>
                  Now
                  <span className="progressbar-spn1"></span>
                  <span className="progressbar-spn"></span>
                </li>
                
                <li >
                  Today 09.30 Am
                  <span className="progressbar-spn1"></span>
                  <span className="progressbar-spn"></span>
                </li>
                
                </ul>
                </div>
 
                <div id="sld1">
                <IonSlides  options={slideSelection}>
                  <IonSlide>
                    <IonCard id="card1" >
                        <div id="div-cad-butt">
                         WD

                        </div>  
                        <div id="cad1-heder2">
                                <div id="card1-heder2-1">Web Developement</div>
                               
                                <div id="cad1-heder2-2">Mr Kartick started a live classes</div>
                                <div
                                 id="btnn"   
                            >
                              <div onClick={() => setShowActionSheet(true)}>
                                  <IonIcon color="primary" size="small"  slot="start" icon={videocamSharp}/>
                                  </div>
                                  <div className="act">
                                  Join Class
                                  </div>
                                  </div>
                            </div>
                       
                            
 
                        </IonCard>
                  </IonSlide>
                  <IonSlide>
                  <IonCard id="card1">
                        <div id="div-cad-btn2">
                          SS

                        </div>  
                        <div id="cad1-heder2">
                                <div id="card1-heder2-1">System Software</div>
                               
                                <div id="cad1-heder2-2">Mr alpana schedule a live class</div>
                                <div
                        id="btnn2"
                            >
                                  <div><IonIcon color="primary" size="small"  slot="start" icon={time}/>
                                  </div> 
                                  
                                  <div className="act1">09:30Am -12:00 Pm</div>
                               </div>
                            </div>
                       
                           
 
                        </IonCard>
                  </IonSlide>

                </IonSlides>
                </div>
               
                <div className="instruct1">
              <div>My Classes</div>
              <div>
                <Link className="lnk" to='/myclass'>View all</Link>
              </div>
            </div>
   
            <IonSlides id="scnd-slides"  options={slideSelection2}>
                  <IonSlide className="slide" >
                    <IonCard id="card2" >
                        <div className="div-cad-button3">
                          WD

                        </div>  
                        <div id="cad1-heder3">
                                <div id="card1-heder3-1">Web Developement</div>
                               
                                <div id="card1-heder3-2">Mr Kartick started a live classes</div>
                                <div 
                                 id="tch-btnn2"  
                            >
                                  <IonIcon color="dark" size="small"  slot="start" icon={personCircleSharp}>
                                  </IonIcon> 
                                  Teacher name
                               </div>
                            </div>
                       
                            
 
                        </IonCard>
                  </IonSlide>
                  <IonSlide >
                  <IonCard id="card2" >
                        <div className="div-cad-button4">
                          SS

                        </div>  
                        <div id="cad1-heder3">
                                <div id="card1-heder3-1">System Software</div>
                               
                                <div id="card1-heder3-2">Mr alpana schedule a live classes</div>
                                <div id='tch-btnn2'>
                                  <IonIcon color="dark" size="small"  slot="start" icon={personCircleSharp}>
                                  </IonIcon> 
                                  
                                  <div>Teacher name</div>
                               </div>
                            </div>
                  </IonCard>
                  </IonSlide>
                  </IonSlides> 
                  

    </IonContent>
    <IonToolbar className='ion-toolbar'style={{height:"60px"}}>
                  
                    <IonTabs className='ion-tabs'>
                      <IonRouterOutlet>

                      </IonRouterOutlet>
                  <IonTabBar className="tab-bar" slot="bottom" >
                    
                    <IonTabButton href='/class' tab='class'>
                     <IonIcon icon={readerOutline} ></IonIcon>
                    </IonTabButton >
                    <IonTabButton href='/liveclass' >
                      <IonIcon icon={documentTextOutline}></IonIcon>
                    </IonTabButton>
                    <IonTabButton  href='/'  tab='home'>
                      <div className="home-btn">
                     <IonIcon size="large" style={{color:"white"}}icon={homeOutline}></IonIcon></div>
                    </IonTabButton>
                    <IonTabButton href='/pending'  tab='file'>
                      <IonIcon icon={fileTrayFullOutline}></IonIcon>
                    </IonTabButton >
                    <IonTabButton href='/contact'  tab='contact'>
                      <IonIcon icon={peopleOutline}></IonIcon>
                    </IonTabButton>
                
                    </IonTabBar>
                    </IonTabs>
                   
                    </IonToolbar>
      
                    <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass='my-custom-class'
       header="How do you want to join the class ?"
        mode='ios'
       
        buttons={[{
          text: vdoTextOff,
          role:destructive,
          icon: videoOff,
           
          handler: () => {
            setDestructive("");
           setVideoOn(videocam);
           setVdoText("Video is On");
          
          }
        }, {
          role:destructive1,
          text: mictextOff,
          icon: micbarOff,
          handler: () => {
            setDestructive1("");
          setMicOn(mic);
          setMicText(" Mic is On")
          }
        }, {
          text: 'device',
          icon: phoneLandscape,
          handler: () => {
            console.log('phone clicked');
          }
        },{
          text: 'Join Class',
          icon:videocamSharp,
          role:"cancel",
          cssClass:"joinClass"
        }
        
        ]}
      >
      </IonActionSheet>
                 
</IonApp>
);

      }     
       
     
     
  


export default Home;
