import React from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Home from './Home';
//import Route from './Components/Route';
import PendingAssignments from './Components/PendingAssignments';
import YourClasses from './Components/YourClasses';
import JoinClass from './Components/JoinClass';
import LiveClasses from './Components/LiveClasses';
//import LiveClasses from'./Components/Date';
import history from './Components/History';
const App :React.FC=()=>{

    return(
        <>
        <BrowserRouter >
       <Switch>
        <Route  exact  path="/" component={Home}/>
        <Route path="/pending"  component={PendingAssignments}></Route>
        <Route  path="/joinclass"  component={JoinClass}/>
        <Route  path="/liveclass"  component={LiveClasses}/>
        <Route  path="/myclass"  component={YourClasses}></Route>
            </Switch>
</BrowserRouter>
        </>
    );
}

export default App;