import React from 'react';
import {IonApp, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonSearchbar, IonSlide, IonSlides, IonToolbar } from '@ionic/react';
import './PendingAssignments.css';
import {Link} from 'react-router-dom';
import '@ionic/react/css/core.css';
import {chevronBackSharp,searchSharp,person,time} from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */

const PendingAssignments: React.FC=()=>{
    return( 
            <IonApp style={{maxWidth:"450px",maxHeight:"750px"}}>
                 <IonContent scrollEvents={true}>
            <IonHeader  className="ion-no-border">
             <div id='header'>
             <IonButtons slot="start" >
          <IonBackButton defaultHref='/'/>
          </IonButtons>
                 <div className="div2">Pending assignments</div>
                </div>
                <div id="div-search">
       <div>
           <IonSearchbar
            placeholder="Search for courses" className="search-bar"
              mode='ios' /> 
             </div>
            <div className="ion-button">
            <IonIcon  style={{color:"white"}} size="medium" icon={searchSharp}></IonIcon>
            </div>
        </div>
        </IonHeader>
            
             <div   className="slides">
                  
                  <div id="item">
                      <div  id="card1-header">
                      <div className="div-card-button">
                            WD
                            </div>

                            <div id="card1-header2">
                               <div className="card1-header2-1">Web Developement</div>
                               <div className="card1-header2-2">Assignment 1</div>
                            </div>
                      </div> 
                      <div className="card1-content">
                          <div id="card1-content1">Create a for loop that iterates up to 100 while outputting
                              "fizz" at multiples of 3 "buzz" at multiples of 5 and...
                              </div>
                              
                              <div id="content2">
                                  <div>
                              <IonIcon 
                              color="primary" slot="start" icon={person}></IonIcon></div>
                              <span>JJ Thomson</span>
                              </div>

                              <div id="content3">
                                  <div>
                              <IonIcon 
                              color="primary" slot="start" icon={time}></IonIcon></div>
                              <span style={{color:"red"}}>08-10-20</span>
                              <span>1 day left</span>
                              </div>
                           </div>
                      </div>
                  
                 
                 
                  <div id="item">
                      <div  className="card2-header">
                  <div id="div-card-button2">
                            AC
                            </div>

                            <div id="card1-header2">
                               <div className="card1-header2-1">Advanced Computers</div>
                               <div className="card1-header2-2">Assignment 1</div>
                            </div>
                      </div> 
                      <div className="card1-content">
                          <div id="card1-content1">Create a for loop that iterates up to 100 while outputting
                              "fizz" at multiples of 3 "buzz" at multiples of 5 and...
                              </div>
                              
                              <div id="content2">
                                  
                                  <div>
                              <IonIcon 
                              color="primary" slot="start" icon={person}></IonIcon></div>
                              <span>JJ Thomson</span>
                              </div>

                              <div id="content3">
                              <div>
                              <IonIcon 
                              color="primary" slot="start" icon={time}></IonIcon></div>
                              <span >08-10-20</span>
                              <span>7 days left</span>
                              </div>
                           </div>
                           </div>
                      
                  <div id="item">
                      <div  className="card3-header">
                  <div id="div-card-button3">
                            SA
                            </div>

                            <div id="card1-header2">
                               <div className="card1-header2-1">Storage Area Networks</div>
                               <div className="card1-header2-2">Assignment 1</div>
                            </div>
                      </div> 
                      <div className="card1-content">
                          <div id="card1-content1">Create a for loop that iterates up to 100 while outputting
                              "fizz" at multiples of 3 "buzz" at multiples of 5 and...
                              </div>
                              
                              <div id="content2">
                             
                              <div>
                              <IonIcon 
                              color="primary" slot="start" icon={person}></IonIcon></div>
                              <span>JJ Thomson</span>
                              </div>

                              <div className="content3-3">
                              <div>
                              <IonIcon 
                              color="primary" slot="start" icon={time}></IonIcon></div>
                              <span >08-10-20</span>
                              <span className="days-sp">12 days left</span>
                              </div>
                           </div>
                           </div>



                  </div>
                  </IonContent>
                    </IonApp>
    );
}

export default PendingAssignments;   

       

        
       
   
               

