import React,{useState} from 'react';
import { IonButton, 
     IonHeader, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { chevronBackSharp } from 'ionicons/icons';
import './JoinClass.css';
const JoinClass:React.FC=()=>{
 

  

    return(
 
        <>
            <IonHeader className="ion-no-border">
                <div className='hr'>
                    <div >
                        <IonIcon style={{ color: "rgb(82, 82, 82)" }} size='large' slot='start' icon={chevronBackSharp} />
                    </div>
                    <div id="di2">Join Class</div>
                </div>
            </IonHeader>
            <div className="ion-div">
                <IonItem  className='ion-no-padding ion-item'>
                    <IonLabel id="input-label" position="floating">
                        <div id="div-label">Class Code(Ask teacher,enter here)</div></IonLabel>
                    <IonInput 
                      id="input" inputmode='tel'
                        type="text"></IonInput>

                </IonItem>
            </div>
             
            <IonButton id="in-btn" expand="block"
           color="primary" >

                 Join

        </IonButton>
        
           
           
      </>
    );
}

export default JoinClass;