import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Home from './Home';
import {BrowserRouter,Router} from 'react-router-dom';
import '@ionic/react/css/core.css';
import './theme/variables.css';
import Time from './Components/Time';
import ActionSheet from './Components/ActionSheet';
ReactDOM.render(<App/>
        , document.getElementById('root'));


