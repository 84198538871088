
import dayjs from 'dayjs';
import React,{useState} from 'react';
import './time.css';
import {IonSlides,IonSlide} from'@ionic/react';
const Time=()=>{

  const [slideActive ,setSlidesActive]=useState("0");
  const month= dayjs().format('MMMM');
 const tarik= dayjs().format("D");
 const date1=dayjs().format('dddd');
 

 console.log(slideActive)
 const slide=()=>{
const slides=[];
for(var i=0;i<=100;i++){
const styleSlide={backgroundColor:"rgb(48, 132, 242)",color:"white"}
const active=i===slideActive? "active-" :""
slides.push(
  
  <IonSlide  className={`${active}calender-slide`} key={i} >
  <div  className="calender" >
    <div>
    {(dayjs().add(i,('d')).format('D'))}
  </div>
  <div>
 {(dayjs().add(i,('d')).format('ddd'))}
  </div>
  <div  className="div" >
   <span>
   <span> 
  <span>   
  </span>   
   </span> 
   </span>
  </div>
</div>
</IonSlide>

   
)}
return slides;
    
     
   }
 
 const slidesOpt={ 
   slidesPerView:5,
 autoplay:300,
 speed:1000,
 
 }
 
 const getIndex = async (event) => {
  let index = 0;
  await event.target.getActiveIndex().then((value)=> (index=value));
 setSlidesActive(index);
 
}

//className='active'
 
    return(
      <>
       <div className="title">{`${month} ,${tarik}`}<span className="spn">{date1}</span></div>
        <IonSlides onIonSlideTransitionStart={(event)=> getIndex(event)}
         className="calender-slides" 
        options={slidesOpt}>
{slide()}
  </IonSlides>
  </>
    );
}

export default Time;