
import './LiveClasses.css';
import React, { useState } from 'react';
import { IonActionSheet, IonApp, IonBackButton, IonButtons, IonContent } from '@ionic/react';
import {micOff,mic,videocam,videocamOff,phoneLandscape, starHalfOutline, notificationsSharp} from 'ionicons/icons';
import {IonHeader, IonIcon } from '@ionic/react';
import { chevronBackSharp,videocamSharp,notifications,time } from 'ionicons/icons';
import Time from "./Time";
import {Link} from 'react-router-dom';
const LiveClasses:React.FC=()=>{
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [micbarOff,setMicOn]=useState(micOff);
  const [mictextOff,setMicText]=useState('Mic is off');
  const [vdoTextOff,setVdoText]=useState('Video is off')
  const [videoOff,setVideoOn]=useState(videocamOff);
  const [destructive,setDestructive]=useState("destructive");
  const [destructive1,setDestructive1]=useState("destructive");

  
    return(
        <IonApp style={{maxWidth:"450px",maxHeight:"750px"}}>
        
     
        <IonContent fullscreen>
                <div className='headr'>
               
                <IonButtons slot="start" >
          <IonBackButton defaultHref='/'/>
          </IonButtons>
                    <div id="div_2">Live Classes</div>
                </div>
               <Time/>
                
            
            <div>
                
                
              <div className='progresbar'>
            
                <div className='active'>
                <span className="span1"></span>
                <div className="div">
                <div className='class-time' style={{color:"rgb(48, 132, 242)"}}>Now</div>
                <div className="card1">
                  <div className="card1-headr" >
                   <div style={{color:"white"}} className="div-card-button">
                             WD
                             </div>

                             <div id="card1-heder2">
                                <div id="heder2-1">Web Developement</div>
                                <div id="card1-heder2-2">Mr Kartick started live</div>
                             </div>
                             </div>
                           <div id="card1-content">
                           
                           <div id="btn-jn"  
                            onClick={() => setShowActionSheet(true)}>
                               <div>
                                  <IonIcon style={{padding:"5px"}}
                                  size="small" color="primary" slot="start" icon={videocam}/>
                                  </div> 
                                 <div className="jn">Join Class</div> 
                               </div>
                               </div>
                           </div>
                           </div>
                           </div>
                              
                   <div>
                <span></span>
                
                <div className="div">
                <div className='class-time'>09:30 AM</div>
                <div className="card1">
                  <div className="card1-headr" >
                   <div style={{color:"white"}} className="div-card-button2">
                             SS
                             </div>

                             <div id="card1-heder2">
                                <div id="heder2-1">System Software</div>
                                <div id="card1-heder2-2">Ms alpana schedule a class</div>
                             </div>
                             </div>
                           <div id="card1-content">
                           
                           <div id="btn1">
                            
                                  <IonIcon size="medium" color="primary" slot="start" icon={notificationsSharp}/>
                                  
                                  <div className="rm">Remind Me</div>
                               </div>
                               <div id="card1-content2">
                                 
                               <IonIcon size="medium"
                               color="primary" slot="start" icon={time}/>
                               <div className="somoy">09:30AM - 12:00PM</div>
                              
                               </div>
                               </div>
                           </div>
                           </div>
                </div>



                <div>
                <span></span>
                <div className="div">
                <div className='class-time'>01:30 PM</div>
                <div className="card1">
                  <div className="card1-headr" >
                   <div style={{color:"white"}} className="div-card-button3">
                             OS
                             </div>

                             <div id="card1-heder2">
                                <div id="heder2-1">Operating System</div>
                                <div id="card1-heder2-2">Ms alpeen schedule a live class</div>
                             </div>
                             </div>
                           <div id="card1-content">
                           
                           <div id="btn2">
                             
                                  <IonIcon
                                   size="medium"
                                   color="primary" slot="start" icon={notificationsSharp}/>
                                   
                                  <div className="rm">Remind me</div>
                               </div>
                               <div id="card1-content2">
                                
                               <IonIcon 
                               color="primary" size="medium"  icon={time}></IonIcon>
                               <div  className="somoy">1:30PM - 03:30PM</div>
                              
                               </div>
                               </div>
                           </div>
                           </div>
               <div></div>
                </div>
                
              </div>

            </div>
            <div  >
            <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass='my-custom-class'
       header="How do you want to join the class ?"
        mode='ios'
       
        buttons={[{
          text: vdoTextOff,
          role:destructive,
          icon: videoOff,
           
          handler: () => {
            setDestructive("");
           setVideoOn(videocam);
           setVdoText("Video is On");
          
          }
        }, {
          role:destructive1,
          text: mictextOff,
          icon: micbarOff,
          handler: () => {
            setDestructive1("");
          setMicOn(mic);
          setMicText(" Mic is On")
          }
        }, {
          text: 'device',
          icon: phoneLandscape,
          handler: () => {
            console.log('phone clicked');
          }
        },{
          text: 'Join Class',
          icon:videocamSharp,
          role:"cancel",
          cssClass:"joinClass"
        }
        
        ]}
      >
      </IonActionSheet>
      </div>
      </IonContent>
        </IonApp>

    )
}

export default LiveClasses;